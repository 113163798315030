export const REQUEST_SCORE_URL = 'https://get.unitq.com/unitq-score-analyzer?utm_source=website';

export const Content = {
  request: {
    link: {
      href: REQUEST_SCORE_URL,
      label: 'Request Your unitQ Score',
    },
    message: 'Can’t find what you’re looking for? No problem!',
  },
  search: {
    noResults: 'There were no results for the search above.',
    placeholder: 'Search Companies',
  },
  seo: {
    appendHostUrl: true,
    description: 'unitQ Scorecards Catalog',
    src: '/images/v2/seo/home.jpg',
    title: 'unitQ Scorecards Catalog',
  },
  subtitle: 'Find your scorecard and discover what users think about your products and services.',
  title: 'unitQ Scorecard List',
};
