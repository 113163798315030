import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from '@uq-assets/arrow-right.svg';
import { UqPage, UqLayoutV2, UqContainer, UqScoreCardGroup, UqScoreCardListAnchors, UqLink, UqSearchBar, UqRequestDemoBanner, UqScrollAnchor } from '@uq-components';
import { usePageAnchors, useSearch } from '@uq-hooks';

import { Content } from '../../content/v2/unitq-scorecards';
import NoResultsImage from '../../content/v2/unitq-scorecards/assets/no-results.svg';

import * as styles from './unitq-scorecards.module.scss';

const AppShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
});

const AppGroupShape = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(AppShape),
  ]),
);

UqScoreCardsPage.propTypes = {
  pageContext: PropTypes.shape({
    scoreCards: PropTypes.arrayOf(AppGroupShape),
  }),
};

export default function UqScoreCardsPage(props) {
  const { scoreCards } = props.pageContext;

  const [groupIndex, setGroupIndex] = useState(null);

  const items = useMemo(() => scoreCards.flatMap(([, list]) => list), []);

  const { executeQuery, results } = useSearch(items, ['title']);
  const { addAnchor, scrollToAnchor } = usePageAnchors();

  const handleInput = useCallback((v) => executeQuery(v), [executeQuery]);

  const clearGroupIndex = useCallback(() => setGroupIndex(null), []);

  useEffect(() => {
    if (groupIndex) {
      scrollToAnchor(groupIndex);
    }
  }, [groupIndex]);

  const isEmptyQuery = results && !results.length;

  return (
    <UqPage config={{ seo: Content.seo }}>
      <UqLayoutV2>
        <UqContainer>
          <section className={styles.headerContainer}>
            <div className={styles.title}>
              <h1>{Content.title}</h1>
              <p className="body-1">{Content.subtitle}</p>
            </div>
            <UqSearchBar
              info={isEmptyQuery
                ? Content.search.noResults
                : undefined}
              placeholder={Content.search.placeholder}
              onChange={handleInput}
              variant="rounded"
            />
            {!results && (
              <UqScoreCardListAnchors
                onSelect={setGroupIndex}
                value={groupIndex}
              />
            )}
          </section>
          {isEmptyQuery ? renderEmpty() : renderList()}
          <div className={styles.requestScore}>
            <span className={styles.requestScoreMessage}>
              {Content.request.message}
            </span>
            <UqLink
              to={Content.request.link.href}
              external
            >
              {Content.request.link.label}
            </UqLink>
          </div>
          <UqRequestDemoBanner />
        </UqContainer>

        <UqScrollAnchor onScroll={clearGroupIndex}>
          <ArrowIcon className={styles.anchorTopIcon} />
        </UqScrollAnchor>
      </UqLayoutV2 >
    </UqPage >
  );

  function renderEmpty() {
    return (
      <div className={styles.imageContainer}>
        <NoResultsImage />
      </div>
    );
  }

  function renderList() {
    return (
      <section className={styles.listContainer}>
        {scoreCards.map(([groupKey, apps], index) => {
          let filtered = [...apps];

          if (results) {
            filtered = apps.reduce((res, curr) => {
              for (const result of results) {
                if (result.title === curr.title) {
                  res.push(result);
                }
              }

              return res;
            }, []);
          }

          if (!filtered.length) {
            return null;
          }

          return (
            <div
              className={styles.group}
              key={index}
              ref={(ref) => addAnchor(groupKey, ref)}
            >
              <UqScoreCardGroup
                groupKey={groupKey}
                apps={filtered}
              />
            </div>
          );
        })}
      </section>
    );
  }
}
